<template>
  <div class="order-detail">
    <div class="order-detail-top f-c">
      <div class="f-l order-detail-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="order-detail-top-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l order-detail-top-title">
        {{ $t("el.common.orderManagement") }} >
        {{ $t("el.order.orderDetail") }}
      </div>
    </div>

    <div class="detail-info">
      <el-descriptions :title="$t('el.order.orderInfo')">
        <el-descriptions-item :label="$t('el.order.orderNo')">{{
          detailInfo.orderInfoVO.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.createTime')">{{
          detailInfo.orderInfoVO.createTime
            ? $utils.getFormatterDate(
                new Date(detailInfo.orderInfoVO.createTime)
              ) +
              " " +
              $utils.getFormatterTime(
                new Date(detailInfo.orderInfoVO.createTime)
              )
            : "-"
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.planCourse.userPhoneNumber')">{{
          detailInfo.orderInfoVO.accountPhone
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.orderStatus')">{{
          $store.state.dict.ORDER_STATUS.map((el) => el.dictionaryText)[
            detailInfo.orderInfoVO.status - 1
          ]
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.dingdanjine')"
          >¥{{ detailInfo.orderInfoVO.totalPrice }}</el-descriptions-item
        >
        <el-descriptions-item :label="$t('el.order.shifujine')">{{
          detailInfo.orderInfoVO.actualAmount === null ||
          detailInfo.orderInfoVO.actualAmount === undefined
            ? "-"
            : "¥" + detailInfo.orderInfoVO.actualAmount
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.payBy')">{{
          detailInfo.orderInfoVO.payChannelName || "-"
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.payTime')">{{
          detailInfo.orderInfoVO.payTime
            ? $utils.getFormatterDate(
                new Date(detailInfo.orderInfoVO.payTime)
              ) +
              " " +
              $utils.getFormatterTime(new Date(detailInfo.orderInfoVO.payTime))
            : "-"
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.shoukuanshanghu')">{{
          detailInfo.orderInfoVO.merchantName
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :title="$t('el.order.registeredStudents')">
        <el-descriptions-item :label="$t('el.courseClassManage.studentName')">{{
          detailInfo.orderDetailStudentVO.studentName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.studentNo')">{{
          detailInfo.orderDetailStudentVO.studentNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.schoolResourceManage.school')">{{
          detailInfo.orderDetailStudentVO.schoolName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.planCourse.inGrade')">{{
          detailInfo.orderDetailStudentVO.gradeName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.courseClassManage.InClass')">{{
          detailInfo.orderDetailStudentVO.className
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :title="$t('el.order.courseInfo')"></el-descriptions>

      <xm-table
        ref="xmTable"
        :hideForm="true"
        :fatch-method="fatchMethod"
        :columns="columns"
        :showPagination="false"
        :showTotal="false"
        noEmptyText="el.common.noData"
      >
      </xm-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { orderDetailInfoApi } from "@/api/manage/order";
import { handparams } from "@/utils";

export default {
  components: {},
  computed: {
    ...mapGetters({
      termOptionsOrigin: "termOptions",
      schoolYearOptionsOrigin: "schoolYearOptions",
    }),
    ...mapState({
      tenantId: (state) => state.schoolCode,
    }),
    // 学期下拉
    termOptions() {
      return [
        { value: "", label: this.$t("el.planCourse.all") },
        ...this.termOptionsOrigin,
      ];
    },
    // 学年下拉
    schoolYearOptions() {
      return [
        { value: "", label: this.$t("el.planCourse.all") },
        ...this.schoolYearOptionsOrigin,
      ];
    },
    columns() {
      return [
        {
          label: this.$t("el.order.orderItemNo"),
          prop: "orderItemNo",
        },
        {
          label: this.$t("el.order.courseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.planCourse.courseSource"),
          prop: "courseResource",
        },
        {
          label: this.$t("el.planCourse.affiliation"),
          prop: "courseOrigination",
        },
        {
          label: this.$t("el.schoolResourceManage.grade"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.courseList.subject"),
          prop: "subjectName",
        },
        {
          label: this.$t("el.planCourse.courseCategory"),
          prop: "categoryName",
        },
        {
          label: this.$t("el.planCourse.courseClass"),
          prop: "courseClass",
        },
        {
          label: this.$t("el.planCourse.coursePrice"),
          prop: "coursePrice",
        },
      ];
    },
  },
  data() {
    return {
      defaultImage: require("@/assets/images/default-image.png"), // 默认封面
      detailInfo: {
        orderInfoVO: {},
        orderDetailStudentVO: {},
        orderDetailCourseVOS: [],
      },
      courseClassOptions: [], // 课程班级下拉项
      // schoolYearOptions: [],
    };
  },
  mounted() {
    // this.handleGetCourseDetail();
    // this.handleGetClass();
    // this.fatchMethod();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // // 获取课程详情数据
    // handleGetCourseDetail() {
    //   orderDetailInfoApi(this.$route.query.orderNo).then((res) => {
    //     this.detailInfo = res?.data || {};
    //   });
    // },
    // 获取列表数据
    async fatchMethod(params) {
      //   const data = handparams({
      //     ...params,
      //     orderNo: this.$route.query.orderNo,
      //   });
      //   delete data._orderTime;
      let result = await orderDetailInfoApi(this.$route.query.orderNo);
      if (result.data) {
        if (!result.data.orderInfoVO) result.data.orderInfoVO = {};
        this.detailInfo = result.data;

        return new Promise((resolve) => {
          resolve({
            data: {
              records: result.data.orderDetailCourseVOS,
            },
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order-detail {
  padding: 18px 16px 50px;
  background-color: #fff;
  border-radius: 10px;
  &-top {
    height: 32px;
    line-height: 32px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
  }

  .detail-info {
    margin: 25px 0 30px;
    .details-header-icon {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      margin-left: 9px;
    }
    .details-course-info {
      margin-left: 21px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      display: flex;
      flex-direction: column;
      .details-course-name {
        font-size: 18px;
        font-weight: 500;
        color: #131313;
        margin-bottom: 20px;
      }
      .details-course-desc {
        &.info {
          display: flex;
          flex-wrap: wrap;
          .details-desc-item {
            margin-bottom: 10px;
          }
        }
        & + .details-course-desc {
          padding-top: 10px;
        }
        .details-desc-item {
          margin-right: 63px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  /deep/ .content-cell-wrap {
    .cell {
      text-overflow: unset;
      -webkit-line-clamp: unset;
      white-space: normal;
    }
  }
}
</style>
